import React from 'react'
import {graphql, Link, useStaticQuery} from 'gatsby'
import '../global.scss'
import {Helmet} from "react-helmet"
import "../bootstrap.min.css"
import "../global.scss"
import "../../static/fonts/BlenderPro/blenderPro.css"
import faviconNew from "../favicon.png";
import Footer from "./Footer";

const Layout = ({children, location}) => {
    const {data} = useStaticQuery(query)
    const siteUrl = data.siteMetadata.siteUrl
    const socialImg = siteUrl + '/social/social-image.png';
    const defaultTitle = "TRONDAO AI";
    const defaultDescription = "TRONDAO Artificial Intelligence Funding"

    return (
        <>
            <Helmet>
                <title>{defaultTitle}</title>
                <meta http-equiv="content-type" content="text/html; charset=UTF-8"/>
                <meta name="viewport"
                      content="width=device-width, initial-scale=1.0, user-scalable=0, minimum-scale=1.0, maximum-scale=1.0"/>
                <meta name="description" content={defaultDescription}/>
                <meta name="author" content="TRONDAO.org"/>
                <meta property="og:url" content={siteUrl}/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content={defaultTitle}/>
                <link href={faviconNew} rel="shortcut icon"/>
                <meta property="og:description" content={defaultDescription}/>
                <meta property="og:image" content={socialImg}/>
                <meta property="og:site_name" content="TRONDAO AI"/>
                <meta name="twitter:site" content="@trondao"/>
                <meta name="twitter:image:src" content={socialImg}/>
                <meta name="twitter:card" content="summary_large_image"/>
                <link rel="canonical" href={siteUrl} />
            </Helmet>
            <main>
                {children}
            </main>
            <Footer/>
        </>
    )
}

export default Layout

const query = graphql`
query Layout {
    data: site {
        siteMetadata {
          siteUrl
        }
      }
  }
`

function stripHTML(string) {
    return string.replace(/<(.|\n)*?>/g, '');
}