import React from "react"
import footerLogo from "../images/footer/Footer logo.png"

const Footer = () => {

    return (
            <footer className="footer">
                <div className="mx-3 d-flex align-items-center justify-content-center footer-divider flex-wrap pt-3">
                    <img src={footerLogo} className="img-fluid"/>
                    <div className="text-center mx-auto row"><p>Copyright © 2023 TRONDAO.AI</p><p>All rights reserved.</p><p>Privacy</p></div>
                </div>

            </footer>
    )
}

export default Footer

