import React from 'react';
import Layout from "../components/layout";
import tronLogo from "../images/home/TRON Logo.png";
import homeImage from "../images/home/home-ai-img.png";
const Index = () => {
    let applyNowUrl = "https://docs.google.com/forms/d/e/1FAIpQLSc9o_Xpgb_C--Jwz4qvktok7LLma9uy1SN9paJRJhZIvbdD7Q/viewform";
    return (
        <Layout>
            <div className="home w-100">
                <div className="d-flex pt-3 align-items-center nav">
                    <div className="ml-md-5 ml-1">
                        <img src={tronLogo} className="img-fluid" alt={"Tron Logo"}/>
                    </div>
                    <div className="nav-header ml-3">TRONDAO.AI</div>
                    <div className="ml-auto mr-3">
                        <a href={applyNowUrl} target="_blank" rel="noopener noreferrer"><button>Apply Now</button></a>
                    </div>
                </div>
                <div className="row no-gutters pb-5 text-white text-center">
                    <div className="col-12 col-md-6 px-5 home-intro">
                        <h1 className="pt-5 mt-5">Where A.I. Meets Decentralization</h1>
                        <p className="text-md-left text-center">Pushing the boundaries of technology in order to empower developers to tear down barriers for users.</p>
                        <div className="text-center text-md-left mb-5">
                            <a href={applyNowUrl} target="_blank" rel="noopener noreferrer"><button className="text-white mt-5">Apply Now ></button></a>
                        </div>

                    </div>
                    <div className="col-12 col-md-6">
                        <div className={"d-flex align-items-center h-100"}>
                            <img src={homeImage} className="img-fluid home-ai" alt={"Tron AI"}/>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
export default Index;